import React, { useContext, useEffect, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "lazysizes"
import AuthContext from "../contexts/AuthContext"
import { Template } from "./Config/variable"
export const MobileMenu = props => {
  const [localitySlug, setlocalitySlug] = useState("bengaluru")
  const { signOutUser, user } = useContext(AuthContext)

  useEffect(() => {
    if (localStorage.getItem("slug")) {
      setlocalitySlug(localStorage.getItem("slug"))
    }
  }, [localitySlug])

  return (
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <Link
          to={"/coworking-spaces/" + localitySlug + "/all/?operatorName=" +
                        "Iksana"}
          className="nav-link"
        >
          Coworking Spaces
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={"/meeting-spaces/" + localitySlug + "/all/?operatorName=" +
                        "Iksana"}
          className="nav-link"
        >
          Meeting Spaces
        </Link>
      </li>

      {!user ? (
        <li className="nav-item">
          <Link
            to="/login/"
            title="Login"
            className="nav-links"
            style={{
              padding: "10px 15px 10px 15px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            {" "}
            Login
          </Link>
        </li>
      ) : (
        <div>
          <li className="nav-item">
            <Link
              to="/my-profile/"
              title="Events"
              className="nav-link teamLinksNav"
            >
              My Profile
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/bookings/"
              title="Events"
              className="nav-link teamLinksNav"
            >
              My Bookings
            </Link>
          </li>
          <li className="nav-item">
            <a
              href="#"
              onClick={() => {
                signOutUser()
                if (typeof window !== `undefined`) {
                  window.location.reload()
                }
              }}
              title="Events"
              className="nav-link teamLinksNav"
            >
              Logout
            </a>
          </li>
        </div>
      )}
    </ul>
  )
}

export const DesktopMenu = props => {
  const [localitySlug, setlocalitySlug] = useState("Dehradun")
  console.log(localitySlug)
  const { signOutUser, user } = useContext(AuthContext)
  useEffect(() => {
    if (localStorage.getItem("slug")) {
      setlocalitySlug(localStorage.getItem("slug"))
    }
    // if (user?.organizationId) {
    //   alert(
    //     "You are a WorqFlexi (B2B) user. You must use the WorqFlexi mobile apps to make your bookings. You are being logged out"
    //   )
    //   signOutUser()
    //   if (typeof window !== `undefined`) {
    //     window.location.reload()
    //   }
    // }
  }, [localitySlug])
  useEffect(() => {
    if (user) {
      if (user.organizationId) {
        alert(
          "You are a WorqFlexi (B2B) user. You must use the WorqFlexi mobile apps to make your bookings. You are being logged out"
        )
        signOutUser()
        window.location.reload()
      }
    }
  }, [user])
  const [menuState, setmenuState] = useState("close")
  const MenuOpen = () => {
    if (menuState === "open") {
      setmenuState("close")
    } else {
      setmenuState("open")
    }
  }
  const lists = props.data.allListings ? props.data.allListings.edges : []
  const cities = props.data.allCities.edges
  // const topCity = ["Bangalore", "Mumbai", "Pune", "Chennai", "Hyderabad"]
  // const brandPlaces = props.data.allListings.slug;

  const cityList = cities.map(local => {
    const citiesnew = lists.filter(
      list =>
        list.node.spaceCity === local.node.city &&
        list.node.spaceType === "Shared Workspace" &&
        list.node.monthPassAvailable === true
    )
    if (citiesnew.length > 0) {
      return (
        <div className="brandplaces p-3" key={local.node.slug}>
          <Link to={"/meeting-spaces/" + local.node.slug + "/"}>
            <span>{local.node.displayCity}</span>
          </Link>
        </div>
      )
    }
  })

  return (
    <div className="collapse navbar-collapse" id="navbarToggler">
      <ul className="navbar-nav mr-auto ">
        <li className="dropdown">
          <Link
            to="#"
            className=" dropdown-toggle"
            id="navbarDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ paddingLeft: "0" }}
          >
            Location{" "}
          </Link>

          {/* Dropdown menus  */}
          <div className="dropdown-menu list-scroll  ">{cityList}</div>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        {!user ? (
          <li className="nav-item">
            <Link
              to="/login/"
              title="Login"
              className="nav-links p-2"
              style={{
                background: "#fff",
                padding: "5px 15px 5px 15px",
                color: "black",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              {" "}
              Login
              <span
                class="StylableButton2545352419__icon wixui-button__icon"
                aria-hidden="true"
                data-testid="stylablebutton-icon"
              >
                <svg
                  data-bbox="4 4 17 16.004"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  height="24"
                  width="20"
                  data-type="shape"
                  className="mx-2"
                >
                  <g>
                    <path
                      d="m13 20.002-.707-.707L19.585 12l-7.293-7.294L13 4l8 8-8 8.002Zm-8.293.002L4 19.297l7.293-7.294L4 4.709l.707-.707 8 8-8 8.002Z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </span>
            </Link>
          </li>
        ) : (
          <li className="nav-item dropdown mr-auto">
            <a
              href="#"
              className="dropdown-toggle nav-link"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://w7.pngwing.com/pngs/831/88/png-transparent-user-profile-computer-icons-user-interface-mystique-miscellaneous-user-interface-design-smile-thumbnail.png"
                style={{ width: "20px", height: "20px", borderRadius: "50%" }}
              />
            </a>
            <ul className="dropdown-menu dropdown-menu-right">
              <li>
                <Link to="/my-profile/" title="Events">
                  My Profile
                </Link>
              </li>
              <li>
                <Link to="/bookings/" title="Events">
                  My Bookings
                </Link>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    signOutUser()
                    if (typeof window !== `undefined`) {
                      window.location.reload()
                    }
                  }}
                  title="Events"
                >
                  Logout
                </a>
              </li>
            </ul>
          </li>
        )}
      </ul>
    </div>
  )
}
