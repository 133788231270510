const spacePartnerName = "SpacePartner"
const spacePartnerLogo =
  "https://static.wixstatic.com/media/9d5ddd_a577943636ad4b58a68e7f599a3985e1~mv2.png/v1/fill/w_170,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Iksana-%20black.png"
const loginarrow = "https://www.flaticon.com/free-icon/right-arrow_892528"

const gofloaters ="https://assets.gofloaters.com/logo.png"

// Social Media Links

const faceBook = "https://www.facebook.com/pannalco/"
const instagram = "https://www.instagram.com/iksanaworkspaces/"
const linkedin = "https://www.linkedin.com/company/iksanaworkspaces/"
const twitter = "https://twitter.com/gofloaters"

const google = "https://bit.ly/GoFloatersPlayStore"
const apple =
  "https://itunes.apple.com/in/app/gofloaters/id1446010118?mt=8&uo=4&at=11l6hc&app=itunes&ct=fnd"

// Email and phoneNumber

const email = "mailto:connect@pannal.work"
const phoneNumber = "1234567890"

// footer font size h1,h2,h3,h4 and body ,p,a

const font = "18px"
const PopularCities = {
  fontSize: "1.8em",
  fontweight: "bold",
}

export const Template = {
  spacePartnerName,
  spacePartnerLogo,
  email,
  loginarrow,
  gofloaters
}

export const Sociallinks = {
  faceBook,
  instagram,
  linkedin,
  twitter,

  google,
  apple,
}
export const footer = {
  font,
  PopularCities,
}
